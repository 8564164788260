import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, getFormValues } from "redux-form";

import ContentCard from "../common/template/contentCard";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInput from "../common/form/labelAndInput";

import { setModoTela, initForm } from "./dispositivoActions";

class DispositivoForm extends Component {
	componentWillMount() {
		this.props.initForm({ ...this.props.registro });
	}
	render() {
		return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<ContentCardBody>
						<Field readOnly={true} name="tipo_nome" component={LabelAndInput} label="Tipo" placeholder="Informe o Tipo" cols="12 12 12 12" />
						<Field readOnly={true} name="serie" component={LabelAndInput} label="Série" placeholder="Informe a Série" cols="12 12 12 12" />
						<Field name="nome" component={LabelAndInput} label="Nome" placeholder="Informe o Nome" cols="12 12 12 12" />
					</ContentCardBody>
					<ContentCardFooter>
						<Row alignCenter>
							<Grid cols="6 6 4 3">
								<Button text="Salvar" submit type={"success"} icon={"fa fa-check"} />
							</Grid>
							<Grid cols="6 6 4 3">
								<Button text="Voltar" type={"warning"} icon={"fa fa-chevron-left"} event={() => (window.location = "#/")} />
							</Grid>
						</Row>
					</ContentCardFooter>
				</Form>
			</ContentCard>
		);
	}
}

DispositivoForm = reduxForm({
	form: "dispositivoForm",
	destroyOnUnmount: false,
})(DispositivoForm);
const mapStateToProps = (state) => ({
	registro: state.dispositivo.registro,
	formularioValues: getFormValues("dispositivoForm")(state),
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ setModoTela, initForm }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(DispositivoForm);
