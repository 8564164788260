import React from "react";
import { Link } from "react-router-dom";
import { AppBar, Toolbar, Typography, Menu, MenuItem, IconButton, Divider, List, ListItem, ListItemText } from "@material-ui/core";

import {
	AccountCircle,
	ExitToApp as ExitToAppIcon,
	Build as BuildIcon,
	Settings as SettingsIcon,
	Phone as PhoneIcon,
	ArrowDropDown as ArrowDropDownIcon,
	Memory as MemoryIcon,
	Add as AddIcon,
	Edit as EditIcon,
	Delete as DeleteIcon,
	SubdirectoryArrowRight as SubdirectoryArrowRightIcon,
} from "@material-ui/icons";

import { Switch, Route, Redirect } from "react-router";
import { alpha, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	grow: {
		flexGrow: 1,
	},
	menuButton: {
		borderRadius: 0,
		marginRight: theme.spacing(2),
	},
	search: {
		position: "relative",
		// borderRadius: theme.shape.borderRadius,
		borderRadius: 0,
		backgroundColor: alpha(theme.palette.common.white, 0.15),
		"&:hover": {
			backgroundColor: alpha(theme.palette.common.white, 0.25),
		},
		marginRight: theme.spacing(2),
		marginLeft: 0,
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			marginLeft: theme.spacing(3),
			width: "auto",
		},
	},
	searchIcon: {
		padding: theme.spacing(0, 2),
		height: "100%",
		position: "absolute",
		pointerEvents: "none",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	inputRoot: {
		color: "inherit",
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
		transition: theme.transitions.create("width"),
		width: "100%",
		[theme.breakpoints.up("md")]: {
			width: "20ch",
		},
	},
}));

export default (props) => {
	const classes = useStyles();
	const [perfilElement, setPerfilElement] = React.useState(null);
	const [controladoraElement, setControladoraElement] = React.useState(null);

	const isMenuPerfilAberto = Boolean(perfilElement);
	const abrirMenuPerfil = (event) => {
		setPerfilElement(event.currentTarget);
	};

	const isMenuControladoraAberto = Boolean(controladoraElement);
	const abrirMenuControladora = (event) => {
		setControladoraElement(event.currentTarget);
	};

	const menuId = "primary-search-account-menu";
	const renderMenuPerfil = (
		<Menu
			anchorEl={perfilElement}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			id={menuId}
			keepMounted
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			open={isMenuPerfilAberto}
			onClose={() => {
				setPerfilElement(null);
			}}
		>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					paddingTop: 12,
					paddingLeft: 12,
					paddingRight: 12,
				}}
			>
				<div style={{ paddingRight: 8 }}>Nome:</div>
				<div style={{ fontWeight: "bold" }}>{props.sessao.nome}</div>
			</div>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					paddingLeft: 12,
					paddingRight: 12,
					paddingBottom: 12,
				}}
			>
				<div style={{ paddingRight: 8 }}>Email:</div>
				<div style={{ fontWeight: "bold" }}>{props.sessao.email}</div>
			</div>
			<Divider style={{ marginBottom: 12 }} />
			<MenuItem
				onClick={() => {
					window.open("https://drive.google.com/drive/folders/12MoTUe9IkDwBBt35YeJOxz9P9XRB-Qq_?usp=sharing", "_blank");
				}}
			>
				<BuildIcon style={{ paddingRight: 8 }} /> Manual do usuário
			</MenuItem>
			<Divider style={{ marginTop: 12, marginBottom: 12 }} />
			<MenuItem
				onClick={() => {
					window.location.href = "#/usuarioAlterarSenha";
				}}
			>
				<SettingsIcon style={{ paddingRight: 8 }} /> Alterar Senha
			</MenuItem>
			<MenuItem
				onClick={() => {
					window.location.href = "#/usuarioAlterarTelefone";
				}}
			>
				<PhoneIcon style={{ paddingRight: 8 }} /> Alterar Telefone
			</MenuItem>
			<Divider style={{ marginTop: 12, marginBottom: 12 }} />
			<MenuItem
				onClick={() => {
					props.logout();
					setPerfilElement(null);
				}}
			>
				<ExitToAppIcon style={{ paddingRight: 8 }} /> Sair
			</MenuItem>
		</Menu>
	);

	const renderMenuControladora = (
		<Menu
			anchorEl={controladoraElement}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			id={menuId}
			keepMounted
			transformOrigin={{ vertical: "top", horizontal: "right" }}
			open={isMenuControladoraAberto}
			onClose={() => {
				setControladoraElement(null);
			}}
		>
			<List>
				{(props.dispositivos || []).map((item) => {
					return (
						<div key={item.id}>
							<ListItem button>
								<ListItemText
									primary={props.sessao.administrador ? item.nome_administrador : item.nome}
									style={{ marginLeft: 5, marginRight: 40 }}
									onClick={() => {
										props.setDispositivoSelecionado(item);
									}}
								/>
								<IconButton
									edge="end"
									aria-label="comments"
									onClick={() => {
										props.setModoTela("cadastro", item);
										window.location = "#/dispositivo";
									}}
								>
									<EditIcon />
								</IconButton>

								<IconButton
									edge="end"
									aria-label="comments"
									onClick={() => {
										props.excluirDispositivo(item);
									}}
								>
									<DeleteIcon />
								</IconButton>
							</ListItem>
						</div>
					);
				})}
			</List>
			<Divider style={{ marginBottom: 12 }} />
			<Route
				render={({ history }) => (
					<MenuItem
						onClick={() => {
							history.push("/dispositivoUsuarioNovo");
							setControladoraElement(null);
						}}
						style={{ justifyContent: "center" }}
					>
						<AddIcon /> Adicionar
					</MenuItem>
				)}
			/>
		</Menu>
	);

	return (
		<div className={classes.grow}>
			<AppBar position="fixed">
				<Toolbar>
					<div
						style={{
							display: "flex",
							minWidth: "max-content",
							justifyContent: "start",
							width: "50%",
						}}
					>
						<Typography variant="h6" noWrap>
							<Link
								to="/"
								style={{
									color: "#ffffff",
									fontSize: window.innerWidth < 500 ? "0.9rem" : "1.2rem",
								}}
							>
								Estação Meteorológica
							</Link>
						</Typography>
					</div>
					<div
						style={{
							display: "flex",
							minWidth: "max-content",
							justifyContent: "end",
							width: "50%",
						}}
					>
						<button
							style={{
								backgroundColor: "transparent",
								height: "max-content",
								border: "none",
								color: "#ffffff",
								border: "none",
								display: "flex",
								alignItems: "center",
							}}
							onClick={abrirMenuControladora}
						>
							<MemoryIcon />
							{props.dispositivoSelecionado
								? props.sessao.administrador
									? props.dispositivoSelecionado.nome_administrador
									: props.dispositivoSelecionado.nome
								: "Selecione"}
							<ArrowDropDownIcon />
						</button>
						<button
							style={{
								backgroundColor: "transparent",
								color: "#ffffff",
								border: "none",
								height: "max-content",
								border: "none",
								display: "flex",
								alignItems: "center",
							}}
							onClick={abrirMenuPerfil}
						>
							<AccountCircle />
						</button>
					</div>
				</Toolbar>
			</AppBar>
			{renderMenuPerfil}
			{renderMenuControladora}
		</div>
	);
};
