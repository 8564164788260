import React from "react";

export default (props) => (
  <div
    className={`${props.responsive ? "table-responsive" : ""}`}
    style={{
      display: "flex",
      overflowX: "auto",
      maxHeight: "45vh",
      overflowY: "scroll",
    }}
  >
    <table id={props.id} className="table table-hover">
      {props.children}
    </table>
  </div>
);
