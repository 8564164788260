import React from "react";
import { Avatar, Grid, Typography, Card, CardContent } from "@material-ui/core";
import cloudySun from "../../assets/images/cloudy-sun.png";
import { weatherIcons } from "../../assets/weatherIcons.js";
export default (props) => {
  const {
    nomeIcone,
    temperaturaMinimaProximas6Horas,
    temperaturaMaximaProximas6Horas,
    quantidadePrecipitacaoProximas6Horas,
    mediaVelocidadeVentoKmPorHora,
  } = props.previsao;
  const weatherIcon = weatherIcons[nomeIcone];
  return (
    <Grid
      item
      xs={props.xs}
      xl={props.xl}
      sm={props.sm}
      md={props.md}
      lg={props.lg}
      style={{
        width: props.width,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Card
        style={{
          display: "flex",
          boxShadow: "#aaa 0px 1px 5px 2px",
          width: props.width,
          height: 150,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            padding: 10,
            color: "#333",
          }}
        >
          <div
            style={{ display: "flex", alignItems: "center", height: "100%" }}
          >
            <div
              style={{
                display: "flex",
                width: "50%",
                justifyContent: "center",
                height: "70%",
                paddingRight: 10,
              }}
              title="Previsão para as próximas 12h"
            >
              {nomeIcone && (
                <img
                  src={weatherIcon}
                  style={{
                    maxHeight: "100%",
                    maxWidth: "100%",
                    objectFit: "contain",
                  }}
                ></img>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "50%",
                minHeight: "100%",
              }}
              title="Temperatura mínima e máxima das próximas 6h"
            >
              <h2>
                <b style={{ color: "#e73b3b" }}>
                  {temperaturaMaximaProximas6Horas}°C
                </b>
              </h2>

              <h4>
                <b style={{ color: "#39a5b9" }}>
                  {temperaturaMinimaProximas6Horas}°C
                </b>
              </h4>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
            padding: "10px 10px 10px 0",
            fontSize: "0.9rem",
          }}
        >
          <b style={{ fontSize: "1rem", paddingBottom: 5 }}>
            Previsão do Tempo
          </b>
          <div
            title="Precipitação para as próximas 6h"
            style={{
              display: "flex",
              paddingBottom: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 10,
                width: 40,
                height: 40,
                color: "#636262",
                backgroundColor: "#ddd",
                fontSize: "1.3rem",
              }}
            >
              <i className="fas fa-cloud-rain"></i>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: 5,
              }}
            >
              {/* <span>0% Sem chance</span> */}
              <span>{quantidadePrecipitacaoProximas6Horas} mm</span>
            </div>
          </div>
          <div
            title="Velocidade do vento média das próximas 12h"
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 10,
                width: 40,
                height: 40,
                color: "#636262",
                backgroundColor: "#ddd",
                fontSize: "1.3rem",
              }}
            >
              <i className="fas fa-wind"></i>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: 5,
              }}
            >
              <span>{mediaVelocidadeVentoKmPorHora} km/h</span>
            </div>
          </div>
        </div>
      </Card>
    </Grid>
  );
};
