import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Content from "../common/template/content";
import Form from "./dispositivoForm";

import { salvar } from "./dispositivoActions";

class Dispositivo extends Component {
	render() {
		return (
			<div>
				<Content>
					{/*SEMPRE CARREGA O FORM POR PADRAO */}
					<Form onSubmit={this.props.salvar} />
				</Content>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			salvar,
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(Dispositivo);
