import React from "react";

export default (props) => (
  <td
    {...props}
    align={props.alignRight ? "right" : props.alignCenter ? "center" : "left"}
    onClick={props.onClick}
    style={{
      ...props.style,
      borderLeft: props.borderLeft,
      borderRight: props.borderRight,
      padding: props.padding || 2,
      paddingBlock: !props.padding && (props.paddingBlock || 2),
      paddingInline: !props.padding && (props.paddingInline || 2),
      paddingRight: !props.padding && (props.paddingRight || 2),
      verticalAlign: props.verticalAlign || "middle",
      minWidth: props.minWidth,
      color: props.color,

      fontWeight: props.fontWeight,
      maxWidth: props.noWrap ? 0 : props.maxWidth,
      overflow: props.noWrap ? "hidden" : null,
      textOverflow: props.noWrap ? "ellipsis" : null,
      whiteSpace: props.noWrap ? "nowrap" : null,
      rowspan: props.rowspan || 1,
      position: props.sticky && "sticky",
      bottom: props.sticky && -1,
      backgroundColor: props.backgroundColor
        ? props.backgroundColor
        : props.sticky && "#fff",
      boxShadow: props.sticky && "inset 0px 2px 1px 0px rgba(0,0,0,0.20)",
    }}
    title={props.noWrap ? props.children : props.title}
  >
    {props.children}
  </td>
);
