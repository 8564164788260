import axios from "axios";
import consts from "../consts";
import { setError, setErroAPI, setSuccess } from "../common/toast/toast";

export function initForm(registro = {}) {
	return {
		type: "DISPOSITIVO_USUARIO_SET_REGISTRO",
		payload: {
			registro: registro,
		},
	};
}

export function adicionar(registro) {
	return async (dispatch, getState) => {
		try {
			if (!registro.latitude || !registro.longitude) {
				setError("Clique no mapa e selecione uma coordenada para prosseguir");
				return {};
			}
			//carrega o dispositivo
			const primeiraBuscaDispositivo = await axios.get(`${consts.API_URL}/dispositivo/buscarPeloSerial?serie=${registro.numero_serie}&id_tipo=4`);

			const dispositivo = primeiraBuscaDispositivo.data || null;

			//não identificou nenhum dispositivo com o serial informado
			if (!dispositivo) {
				setError("Dispositivo não encontrado");
			} else if (getState().auth.usuario.administrador) {
				//se for um administrador logado, vincula o id_usuario_administrador
				const registroFormatado = {
					...dispositivo,
					id_usuario_administrador: getState().auth.usuario.id,
					nome_administrador: registro.nome_controladora || registro.numero_serie,
					latitude: String(registro.latitude).substring(0, 15),
					longitude: String(registro.longitude).substring(0, 15),
				};

				//Se é administrador permite criar testes
				await axios.put(`${consts.API_URL}/dispositivo`, registroFormatado);
				setSuccess("Operação Realizada com sucesso.");
				window.location.href = window.location.href.replace("dispositivoUsuarioNovo", "");
			} else if (dispositivo.id_usuario) {
				//dispositivo já está em uso por outro usuário
				setError("Dispositivo não está disponível");
			} else {
				const registroFormatado = {
					...dispositivo,
					id_usuario: getState().auth.usuario.id,
					nome: registro.nome_controladora || registro.numero_serie,
					latitude: String(registro.latitude).substring(0, 15),
					longitude: String(registro.longitude).substring(0, 15),
				};
				await axios.put(`${consts.API_URL}/dispositivo`, registroFormatado);
				setSuccess("Operação Realizada com sucesso.");
				window.location.href = window.location.href.replace("dispositivoUsuarioNovo", "");
			}
		} catch (e) {
			setErroAPI(e);
		}
	};
}
