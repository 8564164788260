import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { reduxForm, Field, formValueSelector } from "redux-form";

import Content from "../common/template/content";
import ContentCard from "../common/template/contentCard";
import ContentCardHeader from "../common/template/contentCardHeader";
import ContentCardBody from "../common/template/contentCardBody";
import ContentCardFooter from "../common/template/contentCardFooter";
import Row from "../common/layout/row";
import Grid from "../common/layout/grid";
import Button from "../common/button/button";
import Form from "../common/form/form";
import LabelAndInput from "../common/form/labelAndInput";
import LabelAndInputMask from "../common/form/labelAndInputMask";
import Select from "../common/form/select";

import { setModoTela } from "./usuarioActions";

class UsuarioAlterarSenhaForm extends Component {
	componentWillMount() {}

	render() {
		let readOnly = this.props.excluir ? "readOnly" : "";

		return (
			<ContentCard style={{ display: "flex", alignItems: "center" }}>
				<ContentCardBody style={{ minWidth: 350, maxWidth: 500, paddingTop: 50 }}>
					<Form event={this.props.handleSubmit}>
						<Field
							name="telefone"
							component={LabelAndInputMask}
							mask="(99)99999-9999"
							label="Telefone"
							placeholder="Informe o Telefone"
							cols="12 12 12 12"
							readOnly={readOnly}
						/>
						<Row alignCenter>
							<Button style={{ margin: 15, height: 40, fontSize: "1rem" }} text="Alterar" submit type={"success"} icon={"fa fa-check"} />
						</Row>
					</Form>
				</ContentCardBody>
			</ContentCard>
		);
	}
}

UsuarioAlterarSenhaForm = reduxForm({ form: "usuarioForm", destroyOnUnmount: false })(UsuarioAlterarSenhaForm);
const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	registro: state.usuario.registro,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ setModoTela }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UsuarioAlterarSenhaForm);
