import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Content from "../common/template/content";
import Form from "./usuarioAlterarTelefoneForm";

import { initForm, alterarTelefone } from "./usuarioActions";

class UsuarioAlterarTelefone extends Component {
	componentWillMount() {
		this.props.initForm({ telefone: this.props.sessao.telefone || "" });
	}
	render() {
		return (
			<div>
				<Content>
					<Form onSubmit={this.props.alterarTelefone} />
				</Content>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	modoTela: state.usuario.modoTela,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ initForm, alterarTelefone }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UsuarioAlterarTelefone);
