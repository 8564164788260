const INITIAL_STATE = {
	registro: {},
	modoTela: "lista",
	lista: [],
	dispositivoSelecionado: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case "DISPOSITIVO_MODO_TELA":
			return {
				...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro,
			};

		case "DISPOSITIVO_SELECIONADO":
			return { ...state, dispositivoSelecionado: action.payload };

		case "DISPOSITIVO_LISTADO":
			return {
				...state,
				lista: action.payload, //.data,
				dispositivoSelecionado: state.dispositivoSelecionado
					? action.payload.find((item) => item.id === state.dispositivoSelecionado.id)
					: action.payload[0], //.data[0],
			};

		default:
			return state;
	}
};
