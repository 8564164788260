import React from "react";

export default (props) => (
  <th
    className={props.acoes ? "table-actions" : ""}
    style={{
      borderBottom: "none",
      textAlign: props.alignRight
        ? "right"
        : props.alignCenter
        ? "center"
        : "left",
      verticalAlign: props.verticalAlign || "top",
      minWidth: props.minWidth,
      maxWidth: props.maxWidth || "fit-content",
      width: props.width,
      height: props.height,
      paddingLeft: props.alignRight ? 0 : 2,
      paddingRight: props.alignRight ? 0 : 2,
      color: props.color,
      backgroundColor: props.backgroundColor
        ? props.backgroundColor
        : props.sticky && "#fff",
      position: props.sticky && "sticky",
      top: props.sticky && -1,
      boxShadow: props.sticky && "inset 0px -1px 1px 0px rgba(0,0,0,0.20)",
      ...props.style,
    }}
    colspan={props.colspan}
    title={props.title}
  >
    {props.children}
  </th>
);
