import clearsky_day from "./images/weatherIcons/01d.png";
import clearsky_night from "./images/weatherIcons/01n.png";
import clearsky_polartwilight from "./images/weatherIcons/01m.png";
import fair_day from "./images/weatherIcons/02d.png";
import fair_night from "./images/weatherIcons/02n.png";
import fair_polartwilight from "./images/weatherIcons/02m.png";
import partlycloudy_day from "./images/weatherIcons/03d.png";
import partlycloudy_night from "./images/weatherIcons/03n.png";
import partlycloudy_polartwilight from "./images/weatherIcons/03m.png";
import cloudy from "./images/weatherIcons/04.png";
import rainshowers_day from "./images/weatherIcons/05d.png";
import rainshowers_night from "./images/weatherIcons/05n.png";
import rainshowers_polartwilight from "./images/weatherIcons/05m.png";
import rainshowersandthunder_day from "./images/weatherIcons/06d.png";
import rainshowersandthunder_night from "./images/weatherIcons/06n.png";
import rainshowersandthunder_polartwilight from "./images/weatherIcons/06m.png";
import sleetshowers_day from "./images/weatherIcons/07d.png";
import sleetshowers_night from "./images/weatherIcons/07n.png";
import sleetshowers_polartwilight from "./images/weatherIcons/07m.png";
import snowshowers_day from "./images/weatherIcons/08d.png";
import snowshowers_night from "./images/weatherIcons/08n.png";
import snowshowers_polartwilight from "./images/weatherIcons/08m.png";
import rain from "./images/weatherIcons/09.png";
import heavyrain from "./images/weatherIcons/10.png";
import heavyrainandthunder from "./images/weatherIcons/11.png";
import sleet from "./images/weatherIcons/12.png";
import snow from "./images/weatherIcons/13.png";
import snowandthunder from "./images/weatherIcons/14.png";
import fog from "./images/weatherIcons/15.png";
import sleetshowersandthunder_day from "./images/weatherIcons/20d.png";
import sleetshowersandthunder_night from "./images/weatherIcons/20n.png";
import sleetshowersandthunder_polartwilight from "./images/weatherIcons/20m.png";
import snowshowersandthunder_day from "./images/weatherIcons/21d.png";
import snowshowersandthunder_night from "./images/weatherIcons/21n.png";
import snowshowersandthunder_polartwilight from "./images/weatherIcons/21m.png";
import rainandthunder from "./images/weatherIcons/22.png";
import sleetandthunder from "./images/weatherIcons/23.png";
import lightrainshowersandthunder_day from "./images/weatherIcons/24d.png";
import lightrainshowersandthunder_night from "./images/weatherIcons/24n.png";
import lightrainshowersandthunder_polartwilight from "./images/weatherIcons/24m.png";
import heavyrainshowersandthunder_day from "./images/weatherIcons/25d.png";
import heavyrainshowersandthunder_night from "./images/weatherIcons/25n.png";
import heavyrainshowersandthunder_polartwilight from "./images/weatherIcons/25m.png";
import lightssleetshowersandthunder_day from "./images/weatherIcons/26d.png";
import lightssleetshowersandthunder_night from "./images/weatherIcons/26n.png";
import lightssleetshowersandthunder_polartwilight from "./images/weatherIcons/26m.png";
import heavysleetshowersandthunder_day from "./images/weatherIcons/27d.png";
import heavysleetshowersandthunder_night from "./images/weatherIcons/27n.png";
import heavysleetshowersandthunder_polartwilight from "./images/weatherIcons/27m.png";
import lightssnowshowersandthunder_day from "./images/weatherIcons/28d.png";
import lightssnowshowersandthunder_night from "./images/weatherIcons/28n.png";
import lightssnowshowersandthunder_polartwilight from "./images/weatherIcons/28m.png";
import heavysnowshowersandthunder_day from "./images/weatherIcons/29d.png";
import heavysnowshowersandthunder_night from "./images/weatherIcons/29n.png";
import heavysnowshowersandthunder_polartwilight from "./images/weatherIcons/29m.png";
import lightrainandthunder from "./images/weatherIcons/30.png";
import lightsleetandthunder from "./images/weatherIcons/31.png";
import heavysleetandthunder from "./images/weatherIcons/32.png";
import lightsnowandthunder from "./images/weatherIcons/33.png";
import heavysnowandthunder from "./images/weatherIcons/34.png";
import lightrainshowers_day from "./images/weatherIcons/40d.png";
import lightrainshowers_night from "./images/weatherIcons/40n.png";
import lightrainshowers_polartwilight from "./images/weatherIcons/40m.png";
import heavyrainshowers_day from "./images/weatherIcons/41d.png";
import heavyrainshowers_night from "./images/weatherIcons/41n.png";
import heavyrainshowers_polartwilight from "./images/weatherIcons/41m.png";
import lightsleetshowers_day from "./images/weatherIcons/42d.png";
import lightsleetshowers_night from "./images/weatherIcons/42n.png";
import lightsleetshowers_polartwilight from "./images/weatherIcons/42m.png";
import heavysleetshowers_day from "./images/weatherIcons/43d.png";
import heavysleetshowers_night from "./images/weatherIcons/43n.png";
import heavysleetshowers_polartwilight from "./images/weatherIcons/43m.png";
import lightsnowshowers_day from "./images/weatherIcons/44d.png";
import lightsnowshowers_night from "./images/weatherIcons/44n.png";
import lightsnowshowers_polartwilight from "./images/weatherIcons/44m.png";
import heavysnowshowers_day from "./images/weatherIcons/45d.png";
import heavysnowshowers_night from "./images/weatherIcons/45n.png";
import heavysnowshowers_polartwilight from "./images/weatherIcons/45m.png";
import lightrain from "./images/weatherIcons/46.png";
import lightsleet from "./images/weatherIcons/47.png";
import heavysleet from "./images/weatherIcons/48.png";
import lightsnow from "./images/weatherIcons/49.png";
import heavysnow from "./images/weatherIcons/50.png";

const weatherIcons = {
  clearsky_day,
  clearsky_night,
  clearsky_polartwilight,
  fair_day,
  fair_night,
  fair_polartwilight,
  partlycloudy_day,
  partlycloudy_night,
  partlycloudy_polartwilight,
  cloudy,
  rainshowers_day,
  rainshowers_night,
  rainshowers_polartwilight,
  rainshowersandthunder_day,
  rainshowersandthunder_night,
  rainshowersandthunder_polartwilight,
  sleetshowers_day,
  sleetshowers_night,
  sleetshowers_polartwilight,
  snowshowers_day,
  snowshowers_night,
  snowshowers_polartwilight,
  rain,
  heavyrain,
  heavyrainandthunder,
  sleet,
  snow,
  snowandthunder,
  fog,
  sleetshowersandthunder_day,
  sleetshowersandthunder_night,
  sleetshowersandthunder_polartwilight,
  snowshowersandthunder_day,
  snowshowersandthunder_night,
  snowshowersandthunder_polartwilight,
  rainandthunder,
  sleetandthunder,
  lightrainshowersandthunder_day,
  lightrainshowersandthunder_night,
  lightrainshowersandthunder_polartwilight,
  heavyrainshowersandthunder_day,
  heavyrainshowersandthunder_night,
  heavyrainshowersandthunder_polartwilight,
  lightssleetshowersandthunder_day,
  lightssleetshowersandthunder_night,
  lightssleetshowersandthunder_polartwilight,
  heavysleetshowersandthunder_day,
  heavysleetshowersandthunder_night,
  heavysleetshowersandthunder_polartwilight,
  lightssnowshowersandthunder_day,
  lightssnowshowersandthunder_night,
  lightssnowshowersandthunder_polartwilight,
  heavysnowshowersandthunder_day,
  heavysnowshowersandthunder_night,
  heavysnowshowersandthunder_polartwilight,
  lightrainandthunder,
  lightsleetandthunder,
  heavysleetandthunder,
  lightsnowandthunder,
  heavysnowandthunder,
  lightrainshowers_day,
  lightrainshowers_night,
  lightrainshowers_polartwilight,
  heavyrainshowers_day,
  heavyrainshowers_night,
  heavyrainshowers_polartwilight,
  lightsleetshowers_day,
  lightsleetshowers_night,
  lightsleetshowers_polartwilight,
  heavysleetshowers_day,
  heavysleetshowers_night,
  heavysleetshowers_polartwilight,
  lightsnowshowers_day,
  lightsnowshowers_night,
  lightsnowshowers_polartwilight,
  heavysnowshowers_day,
  heavysnowshowers_night,
  heavysnowshowers_polartwilight,
  lightrain,
  lightsleet,
  heavysleet,
  lightsnow,
  heavysnow,
};
export { weatherIcons };
