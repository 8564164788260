import React, { useState } from "react";
import Td from "./td";
import ButtonTable from "./buttonTable";
import Button from "../button/button";

export default (props) => {
  const [isHovered, setHovered] = useState(false);
  //remove os nulos
  const botoes = props.botoes ? props.botoes.filter((item) => !!item) : [];
  const botoesIcone = botoes.filter(
    (botao) => botao.icon || (botao.children && botao.event)
  ); //quando usa mais de um ícone passa o elemento na prop children
  const botoesTexto = botoes.filter((botao) => !botao.icon && !botao.children); //se n tem icone nem childen é botao de texto.Ex: 'Importar'
  const spans = botoes.filter((item) => !item.event && !item.icon);

  //agrupa botoes em pares para cada par ficar abaixo do outro
  const paresBotoesComIcone = [];
  let par = [];
  for (let i = 0; i < botoesIcone.length; i++) {
    if (par.length < 2) {
      par.push(botoesIcone[i]);
    }
    if (par.length == 2) {
      paresBotoesComIcone.push(par);
      par = [];
    }
  }
  return (
    <tr
      style={{
        ...props.style,
        filter:
          props.style && props.style.filter
            ? props.style.filter(isHovered, props.style.backgroundColor) //executa a função que aplica o filtro na cor de fundo da linha
            : "",
      }}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => setHovered(false)}
    >
      {props.celulas ? (
        <>
          {props.celulas}
          {botoes.length ? (
            <Td minWidth={100}>
              {/* RENDERIZA AS MENSAGENS no lugar dos botoes. Ex: 'Fatura isenta' */}
              {spans.map((span) => (isHovered ? span.children : null))}
              {/* RENDERIZA OS BOTÕES DE TEXTO */}
              {botoesTexto.map((botao) => (
                <div
                  style={{
                    display: "flex",
                    minWidth: "100%",
                    marginBottom: 2,
                  }}
                >
                  <Button {...botao} visible={isHovered} />
                </div>
              ))}
              {/* RENDERIZA BOTOES PEQUENOS COM ICONE, ATÈ 3 em linha, 4 ou mais mostra pares um abaixo do outro */}
              {botoesIcone.length < 4 ? (
                <div
                  style={{
                    display: "flex",
                    minWidth: "100%",
                    justifyContent: "end",
                  }}
                >
                  {botoesIcone.map((botao, i) => (
                    <ButtonTable key={i} {...botao} visible={isHovered} />
                  ))}
                </div>
              ) : (
                paresBotoesComIcone.map((par, i) => (
                  <div
                    key={i}
                    style={{
                      display: "flex",
                      minWidth: "100%",
                      justifyContent: "end",
                    }}
                  >
                    {par.map((botao, j) => (
                      <ButtonTable key={j} {...botao} visible={isHovered} />
                    ))}
                  </div>
                ))
              )}
            </Td>
          ) : null}
        </>
      ) : (
        props.children
      )}
    </tr>
  );
};
