import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CssBaseline, Grid, FormLabel } from "@material-ui/core";

import imagemTeste from "../assets/images/imagem_aquario_teste.jpg";

import {
	WatchLater as WatchLaterIcon,
	CalendarToday as CalendarTodayIcon,
	WbSunny as WbSunnyIcon,
	WbIncandescent as WbIncandescentIcon,
	SignalWifi0Bar as SemInternetIcon,
	SignalWifi1Bar as SinalRuimInternetIcon,
	SignalWifi3Bar as SinalBomInternetIcon,
	SignalWifi4Bar as SinalExcelenteInternetIcon,
	AddPhotoAlternate as AddPhotoAlternateIcon,
	RemoveCircleOutline as RemoveCircleOutlineIcon,
	BubbleChart,
	Explore,
} from "@material-ui/icons";
import { DeviceThermostat, FlashOn, WaterDrop, SwapVert, Air } from "@mui/icons-material";

import DashboardCard from "../common/components/dashboardCard";
import CardPrevisaoTempo from "../common/components/cardPrevisaoTempo";

import { setModoTela, getDados, getPrevisaoTempo } from "./dashboardActions";
import { getLista as getListaDispositivo } from "../dispositivo/dispositivoActions";

class Dashboard extends Component {
	state = {
		corBotaoAddImagem: "#ffffff",
		corBotaoRemoveImagem: "#ffffff",
	};

	componentDidMount() {
		this.props.getListaDispositivo(this.props.sessao.id || 0);
		if (this.props.dispositivoSelecionado) {
			this.props.getPrevisaoTempo();
			this.props.getDados();
		}
	}

	componentDidUpdate(prevProps) {
		if (
			JSON.stringify(prevProps.listaDispositivos) !== JSON.stringify(this.props.listaDispositivos) ||
			(JSON.stringify(prevProps.dispositivoSelecionado) !== JSON.stringify(this.props.dispositivoSelecionado) &&
				prevProps.dispositivoSelecionado !== null)
		) {
			this.props.getPrevisaoTempo();
			this.props.getDados();
		}

		const fileSelectorImagem = document.createElement("input");
		fileSelectorImagem.setAttribute("type", "file");
		fileSelectorImagem.addEventListener("change", (data) => {
			let reader = new FileReader();
			let file = data.target.files[0];
			if (file) {
				if (!file.name.match(/.png|.jpg|.jpeg/gi)) {
					alert("Selecione apenas imagens (.PNG, .JPG e .JPEG)");
					return;
				}

				reader.onloadend = () => {
					this.setState({ ...this.state, imagem_aquario: reader.result });
				};
				reader.readAsDataURL(file);
			}
		});

		this.fileSelectorImagem = fileSelectorImagem;
	}
	getLocalStyles() {
		return {
			root: {
				padding: 10,
				paddingBottom: 100,
			},
		};
	}

	render() {
		const style = this.getLocalStyles();
		return (
			<React.Fragment>
				<CssBaseline />

				<Grid container spacing={1} style={style.root}>
					<DashboardCard
						xs={12}
						sm={6}
						md={4}
						lg={4}
						color="blue"
						icon={<WatchLaterIcon />}
						title="ÚLTIMA MENSAGEM"
						subtitle={this.props.registro.tempo_ultima_mensagem}
					/>

					<DashboardCard
						xs={12}
						sm={6}
						md={4}
						lg={4}
						color="red"
						icon={<CalendarTodayIcon />}
						title="DATA E HORA"
						subtitle={this.props.registro.data_hora}
					/>

					<DashboardCard
						xs={12}
						sm={6}
						md={4}
						lg={4}
						color="green"
						icon={<FlashOn />}
						title={`ESTADO DA BATERIA`}
						subtitle={this.props.registro.estado_bateria.percentualBateria + "% " + this.props.registro.estado_bateria.alerta}
					/>
					<DashboardCard
						xs={12}
						sm={6}
						md={4}
						lg={4}
						color="purple"
						icon={<WaterDrop />}
						title={`PRECIPITAÇÃO ${this.props.registro.data_hora.substring(0, 5)}`}
						subtitle={this.props.registro.precipitacao + " mm"}
					/>

					<DashboardCard
						xs={12}
						sm={6}
						md={4}
						lg={4}
						color="yellow"
						icon={
							!this.props.registro.rssi ? (
								<SemInternetIcon />
							) : this.props.registro.rssi == "Ruim" ? (
								<SinalRuimInternetIcon />
							) : this.props.registro.rssi == "Bom" ? (
								<SinalBomInternetIcon />
							) : (
								<SinalExcelenteInternetIcon />
							)
						}
						title="SINAL WI-FI"
						subtitle={(this.props.registro.rssi || "Estação inacessível") + " - Intermediário: " + this.props.registro.intermediario}
					/>
					<DashboardCard
						xs={12}
						sm={6}
						md={4}
						lg={4}
						color="orange"
						icon={<WbSunnyIcon />}
						title="CLIMA ATUAL"
						subtitle={this.props.registro.clima_atual_temperatura + "°, " + this.props.registro.clima_atual_umidade_ar + "%"}
					/>
					<DashboardCard
						xs={12}
						sm={6}
						md={4}
						lg={4}
						color="lightblue"
						icon={<BubbleChart />}
						title="UMIDADE DO SOLO"
						subtitle={this.props.registro.umidade_solo + "%"}
					/>
					<DashboardCard
						xs={12}
						sm={6}
						md={4}
						lg={4}
						color="green"
						icon={<Air />}
						title="VELOCIDADE DO VENTO"
						subtitle={this.props.registro.velocidade_vento + " km/h"}
					/>
					<DashboardCard
						xs={12}
						sm={6}
						md={4}
						lg={4}
						color="blue"
						icon={<Explore />}
						title="DIREÇÃO DO VENTO"
						subtitle={this.props.registro.direcao_vento}
					/>
					<DashboardCard
						height={window.innerWidth < 960 ? null : 150}
						xs={12}
						sm={6}
						md={6}
						lg={6}
						color="red"
						icon={<SwapVert />}
						title={`MÍNIMAS E MÁXIMAS ${this.props.registro.data_hora.substring(0, 5)}`}
						subtitle={
							<>
								<p
									style={{
										margin: window.innerWidth < 960 ? 0 : "10px 0 5px 0",
									}}
								>
									{`${this.props.registro.temperatura_ar_minima || ""}°C - ${this.props.registro.temperatura_ar_maxima || ""}°C`}
								</p>
								<p style={{ margin: window.innerWidth < 960 ? 0 : null }}>
									{`${this.props.registro.umidade_ar_minima || ""}% - ${this.props.registro.umidade_ar_maxima || ""}%`}
								</p>
							</>
						}
					/>
					<CardPrevisaoTempo xs={12} sm={12} md={6} lg={6} width="100%" previsao={this.props.registro.previsao} />
				</Grid>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	modoTela: state.dashboard.modoTela,
	listaDispositivos: state.dispositivo.lista,
	dispositivoSelecionado: state.dispositivo.dispositivoSelecionado,
	registro: state.dashboard.registro,
});
const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			setModoTela,
			getDados,
			getPrevisaoTempo,
			getListaDispositivo,
		},
		dispatch
	);
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
