import axios from "axios";
import consts from "../consts";
import { setErroAPI, setError, setSuccess } from "../common/toast/toast";
import { reset as resetForm, initialize } from "redux-form";

export function setModoTela(modo, registro = {}) {
	return {
		type: "DISPOSITIVO_MODO_TELA",
		payload: {
			modo: modo,
			registro: registro,
		},
	};
}
export function initForm(registro = {}) {
	return [initialize("dispositivoForm", registro)];
}

export function getLista(id_usuario) {
	return async (dispatch, getState) => {
		if (!id_usuario) return {};

		try {
			const dispositivosResp = await axios.get(`${consts.API_URL}/dispositivo?id_usuario=${id_usuario}`);
			const dispositivos = dispositivosResp.data;

			dispatch({
				type: "DISPOSITIVO_LISTADO",
				payload: dispositivos,
			});
		} catch (e) {
			// console.log(e);
			setErroAPI(e);
		}
	};
}

export function setDispositivoSelecionado(dispositivo) {
	return {
		type: "DISPOSITIVO_SELECIONADO",
		payload: dispositivo,
	};
}

export function salvar(registro) {
	return async (dispatch, getState) => {
		try {
			if (!registro.id) {
				const resp = await axios.post(`${consts.API_URL}/dispositivo`, {
					...registro,
				});
				setSuccess("Operação Realizada com sucesso.");
				dispatch(getLista(registro.id_usuario_administrador));
				dispatch(setModoTela("cadastro"));
				dispatch(
					initForm({
						...registro,
						...resp.data,
					})
				);
			} else {
				await axios.put(`${consts.API_URL}/dispositivo`, registro);
				setSuccess("Operação Realizada com sucesso.");
				dispatch(getLista(registro.id_usuario));
				dispatch(setModoTela("lista"));
			}
		} catch (e) {
			// console.log(e);
			setErroAPI(e);
		}
	};
}

export function excluir(registro) {
	return async (dispatch, getState) => {
		if (!getState().auth.usuario.administrador) {
			axios
				.put(`${consts.API_URL}/dispositivo`, {
					...registro,
					id_usuario: null,
					nome: null,
				})
				.then((resp) => {
					setSuccess("Operação Realizada com sucesso.");
					dispatch(setDispositivoSelecionado(null));
					dispatch(getLista(registro.id_usuario));
					window.location.reload();
				})
				.catch((e) => {
					setErroAPI(e);
				});
		} else {
			axios
				.put(`${consts.API_URL}/dispositivo`, {
					...registro,
					id_usuario_administrador: null,
					nome_administrador: null,
				})
				.then((resp) => {
					setSuccess("Operação Realizada com sucesso.");
					dispatch(setDispositivoSelecionado(null));
					dispatch(getLista(registro.id_usuario));
					window.location.reload();
				})
				.catch((e) => {
					setErroAPI(e);
				});
		}
	};
}
