import axios from "axios";
import consts from "../consts";
import { setError, setErroAPI, setSuccess } from "../common/toast/toast";

export function getDados() {
	return async (dispatch, getState) => {
		const usuarioLogado = getState().auth.usuario || {};
		const dispositivoSelecionado = getState().dispositivo.dispositivoSelecionado || {};
		try {
			dispatch({ type: "RESETAR_ALERTA" });

			if (!usuarioLogado.id || !dispositivoSelecionado.id) {
				setError("Nenhum dispositivo selecionado");
				return {};
			}
			const url = `${consts.API_URL}/estacao/alerta?id_usuario=${usuarioLogado.id}&id_dispositivo=${dispositivoSelecionado.id}`;
			const resp = await axios.get(url);
			dispatch({
				type: "ALERTA_LISTADO",
				payload: resp,
			});
		} catch (e) {
			setError("Controladora não respondeu");
		}
	};
}

export function salvar(registro) {
	return async (dispatch, getState) => {
		const usuarioLogado = getState().auth.usuario || {};
		const dispositivoSelecionado = getState().dispositivo.dispositivoSelecionado || {};

		if (!usuarioLogado.id || !dispositivoSelecionado.id) {
			setError("Nenhum dispositivo selecionado");
			return {};
		}
		try {
			if (!registro.id) {
				await axios.post(`${consts.API_URL}/estacao/alerta`, {
					...registro,
					id_usuario: usuarioLogado.id,
					id_dispositivo: dispositivoSelecionado.id,
				});
			} else {
				await axios.put(`${consts.API_URL}/estacao/alerta`, {
					...registro,
				});
			}
			setSuccess("Alertas configurados com sucesso");
			dispatch(getDados());
		} catch (e) {
			setErroAPI("Não foi possível salvar a configuração de alertas");
		}
	};
}
