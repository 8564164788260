import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { reduxForm, Field, formValueSelector } from "redux-form";

import { Button, Grid, Typography, FormControlLabel } from "@material-ui/core";
import Slider from "@mui/material/Slider";
import { CustomSwitch } from "../common/components/CustomSwitch";
import { salvar, getDados } from "./alertaActions";
import { excluirChatIdTelegram } from "../usuario/usuarioActions";
import { getLista as getListaDispositivo } from "../dispositivo/dispositivoActions";
const COR_ATIVA = "#4db6ac";
const COR_INATIVA = "#bdbdbd";

class AlertaForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: null,
			alertaVelocidadeVento: null,
			alertaTemperaturaAr: null,
			alertaUmidadeAr: null,
			alertaPrecipitacao: null,
			alertaUmidadeSolo: null,
			resetarSliderVelocidadeVento: true,
			resetarSliderTemperaturaAr: true,
			resetarSliderUmidadeAr: true,
			resetarSliderPrecipitacao: true,
			resetarSliderUmidadeSolo: true,
			corSliderVelocidadeVento: COR_INATIVA,
			corSliderTemperaturaAr: COR_INATIVA,
			corSliderUmidadeAr: COR_INATIVA,
			corSliderPrecipitacao: COR_INATIVA,
			corSliderUmidadeSolo: COR_INATIVA,
		};
		this.handleChangeSwitch = this.handleChangeSwitch.bind(this);
		this.handleChangeSlider = this.handleChangeSlider.bind(this);
	}

	componentDidMount() {
		this.props.getListaDispositivo(this.props.sessao.id || 0);
		if (this.props.dispositivoSelecionado) {
			this.props.getDados();
		}
	}

	componentDidUpdate(prevProps) {
		if (
			JSON.stringify(prevProps.listaDispositivos) !== JSON.stringify(this.props.listaDispositivos) ||
			(JSON.stringify(prevProps.dispositivoSelecionado) !== JSON.stringify(this.props.dispositivoSelecionado) &&
				prevProps.dispositivoSelecionado !== null)
		) {
			this.props.getDados();
			this.carregarState(this.props.registro);
		}
		if (prevProps.registro.id != this.props.registro.id) {
			this.carregarState(this.props.registro);
		}
	}
	carregarState(registro) {
		//atualiza as coires e os switchs ao trazer o registro do banco
		const configuracaoCoresSwitch = {
			id: registro.id || null,
			resetarSliderVelocidadeVento: isNaN(parseInt(registro.alertaVelocidadeVento)),
			resetarSliderTemperaturaAr: isNaN(parseInt(registro.alertaTemperaturaAr)),
			resetarSliderUmidadeAr: isNaN(parseInt(registro.alertaUmidadeAr)),
			resetarSliderPrecipitacao: isNaN(parseInt(registro.alertaPrecipitacao)),
			resetarSliderUmidadeSolo: isNaN(parseInt(registro.alertaUmidadeSolo)),
			corSliderVelocidadeVento: isNaN(parseInt(registro.alertaVelocidadeVento)) ? COR_INATIVA : COR_ATIVA,
			corSliderTemperaturaAr: isNaN(parseInt(registro.alertaTemperaturaAr)) ? COR_INATIVA : COR_ATIVA,
			corSliderUmidadeAr: isNaN(parseInt(registro.alertaUmidadeAr)) ? COR_INATIVA : COR_ATIVA,
			corSliderPrecipitacao: isNaN(parseInt(registro.alertaPrecipitacao)) ? COR_INATIVA : COR_ATIVA,
			corSliderUmidadeSolo: isNaN(parseInt(registro.alertaUmidadeSolo)) ? COR_INATIVA : COR_ATIVA,
		};
		this.setState({ ...registro, ...configuracaoCoresSwitch });
	}
	getLocalStyles() {
		return {
			root: {
				padding: 10,
				paddingBottom: 100,
				minHeight: "100vh",
				display: "flex",
				alignItems: "center",
				justifyContent: "start",
			},
			gridButton: {
				marginTop: 20,
			},
		};
	}

	getSliderStyle(corSlider) {
		return {
			"& .MuiSlider-thumb": {
				color: corSlider,
			},
			"& .MuiSlider-track": {
				color: corSlider,
			},
			"& .MuiSlider-rail": {
				color: corSlider,
			},
		};
	}
	handleChangeSwitch(e, descricaoCampo) {
		const valorMinimo = descricaoCampo == "TemperaturaAr" ? -10 : 0;
		this.setState({
			...this.state,
			[`alerta${descricaoCampo}`]: e.target.checked ? valorMinimo : null,
			[`resetarSlider${descricaoCampo}`]: !e.target.checked,
			[`corSlider${descricaoCampo}`]: e.target.checked ? COR_ATIVA : COR_INATIVA,
		});
	}

	handleChangeSlider(descricaoCampo, val) {
		this.setState({
			...this.state,
			[`alerta${descricaoCampo}`]: val,
		});
	}
	render() {
		const style = this.getLocalStyles();

		//não existe dispositivo pai
		const modoLeitura = !this.props.dispositivoSelecionado;
		const desabilidarBotaoSalvar =
			!parseInt(this.state.alertaVelocidadeVento) &&
			!parseInt(this.state.alertaTemperaturaAr) &&
			!parseInt(this.state.alertaUmidadeAr) &&
			!parseInt(this.state.alertaPrecipitacao) &&
			!parseInt(this.state.alertaUmidadeSolo) &&
			!this.state.id;

		return (
			<Grid container spacing={1} style={{ ...style.root }} direction="column">
				{modoLeitura && (
					<Grid container direction="row" justifyContent="center" alignItems="center">
						<h6 style={{ marginBottom: 10 }}>(Seleciona um dispositivo para habilitar os alertas)</h6>
					</Grid>
				)}
				<Grid container direction="row" justifyContent="center" alignItems="center" style={{ paddingBlock: 20 }}>
					{this.props.sessao.telefone && this.props.sessao.chat_id_telegram ? (
						<Button
							variant="outlined"
							style={{ color: "#e84444", border: "1px solid #e84444" }}
							onClick={() => {
								this.props.excluirChatIdTelegram();
							}}
						>
							<span style={{ textAlign: "center" }}>Cancelar notificações pelo Telegram</span>
						</Button>
					) : null}
					{this.props.sessao.telefone && !this.props.sessao.chat_id_telegram ? (
						<Button
							variant="outlined"
							href={`https://t.me/AgroMeteorologicoBot?start=${this.props.sessao.telefone}`}
							target="_blank"
							color="secondary"
						>
							<span style={{ textAlign: "center" }}>Clique aqui para receber notificações pelo Telegram</span>
						</Button>
					) : null}
					{!this.props.sessao.telefone ? (
						<Button variant="outlined" href="#/usuarioAlterarTelefone" color="secondary">
							<span style={{ textAlign: "center" }}>Cadastre um telefone para poder receber alertas pelo Telegram</span>
						</Button>
					) : null}
				</Grid>
				<SwitchWithSlider
					min={0}
					max={150}
					modoLeitura={modoLeitura}
					getSliderStyle={this.getSliderStyle}
					idLabel="alerta-velocidade-vento"
					label="Alerta de velocidade do vento"
					handleChangeSwitch={this.handleChangeSwitch}
					handleChangeSlider={this.handleChangeSlider}
					descricaoCampo="VelocidadeVento"
					unidadeMedida=" km/h"
					state={this.state}
				/>
				<SwitchWithSlider
					min={-10}
					max={70}
					modoLeitura={modoLeitura}
					getSliderStyle={this.getSliderStyle}
					idLabel="alerta-temperatura-ar"
					label="Alerta de temperatura do ar"
					handleChangeSwitch={this.handleChangeSwitch}
					handleChangeSlider={this.handleChangeSlider}
					descricaoCampo="TemperaturaAr"
					unidadeMedida=" °C"
					state={this.state}
				/>
				<SwitchWithSlider
					min={0}
					max={100}
					modoLeitura={modoLeitura}
					getSliderStyle={this.getSliderStyle}
					idLabel="alerta-umidade-ar"
					label="Alerta de umidade do ar"
					handleChangeSwitch={this.handleChangeSwitch}
					handleChangeSlider={this.handleChangeSlider}
					descricaoCampo="UmidadeAr"
					unidadeMedida=" %"
					state={this.state}
				/>
				<SwitchWithSlider
					min={0}
					max={400}
					modoLeitura={modoLeitura}
					getSliderStyle={this.getSliderStyle}
					idLabel="alerta-precipitacao"
					label="Alerta de precipitação"
					handleChangeSwitch={this.handleChangeSwitch}
					handleChangeSlider={this.handleChangeSlider}
					descricaoCampo="Precipitacao"
					unidadeMedida=" mm"
					state={this.state}
				/>
				<SwitchWithSlider
					min={0}
					max={100}
					modoLeitura={modoLeitura}
					getSliderStyle={this.getSliderStyle}
					idLabel="alerta-umidade-solo"
					label="Alerta de umidade do solo"
					handleChangeSwitch={this.handleChangeSwitch}
					handleChangeSlider={this.handleChangeSlider}
					descricaoCampo="UmidadeSolo"
					unidadeMedida=" %"
					state={this.state}
				/>
				<Grid container direction="row" justifyContent="center">
					<Grid item xs={12} sm={2} style={{ padding: 10 }}>
						<Button
							fullWidth
							variant="contained"
							color="primary"
							disabled={desabilidarBotaoSalvar}
							onClick={() =>
								this.props.salvar({
									id: this.state.id,
									alertaVelocidadeVento: this.state.alertaVelocidadeVento,
									alertaTemperaturaAr: this.state.alertaTemperaturaAr,
									alertaUmidadeAr: this.state.alertaUmidadeAr,
									alertaPrecipitacao: this.state.alertaPrecipitacao,
									alertaUmidadeSolo: this.state.alertaUmidadeSolo,
								})
							}
						>
							Salvar
						</Button>
					</Grid>
					<Grid item xs={12} sm={2} style={{ padding: 10 }}>
						<Button fullWidth variant="outlined" href="#/" color="secondary">
							Voltar
						</Button>
					</Grid>
				</Grid>
			</Grid>
		);
	}
}

AlertaForm = reduxForm({
	form: "alertaForm",
	destroyOnUnmount: false,
})(AlertaForm);
const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	registro: state.alerta.registro,
	dispositivoSelecionado: state.dispositivo.dispositivoSelecionado,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ salvar, getDados, excluirChatIdTelegram, getListaDispositivo }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AlertaForm);

function SwitchWithSlider({
	unidadeMedida,
	min,
	max,
	modoLeitura,
	getSliderStyle,
	idLabel,
	label,
	handleChangeSwitch,
	handleChangeSlider,
	descricaoCampo,
	state,
}) {
	return (
		<Grid container spacing={1} columns={12} style={{ padding: 10 }}>
			<Grid item xs={2} style={{ display: "flex", justifyContent: "end" }}>
				<FormControlLabel
					style={{
						boxShadow: "#efefef 0px 0px 5px 5px",
						borderRadius: "0.3rem",
					}}
					required
					control={
						<CustomSwitch
							size="small"
							checked={!state[`resetarSlider${descricaoCampo}`]}
							disabled={modoLeitura}
							onChange={(e) => handleChangeSwitch(e, descricaoCampo)}
						/>
					}
				/>
			</Grid>

			<Grid item xs={10}>
				<Typography id={idLabel} gutterBottom>
					{label}
				</Typography>
				<span
					style={{
						marginInline: 10,
						display: "flex",
						flexWrap: "nowrap",
						alignItems: "center",
					}}
				>
					<Slider
						sx={getSliderStyle(state[`corSlider${descricaoCampo}`])}
						aria-labelledby={idLabel}
						style={{ marginRight: 35 }}
						valueLabelDisplay="auto"
						min={min}
						max={max}
						disabled={modoLeitura || !!state[`resetarSlider${descricaoCampo}`]}
						value={!isNaN(parseInt(state[`alerta${descricaoCampo}`])) ? state[`alerta${descricaoCampo}`] : min}
						onChange={(e, val) => handleChangeSlider(descricaoCampo, val)}
					/>
					{!state[`resetarSlider${descricaoCampo}`] || window.innerWidth > 768 ? (
						<span style={{ minWidth: 100 }}>
							{!isNaN(parseInt(state[`alerta${descricaoCampo}`])) ? parseInt(state[`alerta${descricaoCampo}`]) + unidadeMedida : null}
						</span>
					) : null}
				</span>
			</Grid>
		</Grid>
	);
}
