import React from "react";

import { BoxesLoader } from "react-awesome-loaders";
export default () => (
  <div
    style={{
      width: "100vw",
      height: "100vh",
      position: "absolute",
      left: window.innerWidth < 400 ? "30%" : "45%",
      top: window.innerWidth < 400 ? "50%" : "40%",
    }}
  >
    <BoxesLoader
      boxColor={"#4db6ac"}
      desktopSize={"120px"}
      mobileSize={"80px"}
    />
  </div>
);
