import React from "react";

export default (props) => (
  <button
    title={props.title}
    type={"button"}
    className={`btn btn-flat btn-${props.solid ? "" : "outline-"}${props.type}`}
    onClick={() => (props.disabled ? {} : props.event())}
    disabled={props.disabled}
    style={{
      ...props.style,
      width: 40,
      height: 40,
      margin: 1,
      visibility: props.visible ? "visible" : "collapse",
    }}
  >
    {props.children || <i className={props.icon}></i>}
  </button>
);

/*
primary
secondary
success
danger
warning
info
light
dark
link
*/
