const INITIAL_STATE = {
	registro: {
		alertaVelocidadeVento: null,
		alertaTemperaturaAr: null,
		alertaUmidadeAr: null,
		alertaPrecipitacao: null,
		alertaUmidadeSolo: null,
	},
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case "ALERTA_LISTADO":
			return {
				...state,
				registro: { ...action.payload.data },
			};

		case "RESETAR_ALERTA":
			return INITIAL_STATE;

		default:
			return state;
	}
};
