import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Content from "../common/template/content";
import Form from "./usuarioAlterarSenhaForm";

import { initForm, alterarSenha } from "./usuarioActions";

class UsuarioAlterarSenha extends Component {
	render() {
		return (
			<div>
				<Content>
					<Form onSubmit={this.props.alterarSenha} />
				</Content>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	sessao: state.auth.usuario,
	modoTela: state.usuario.modoTela,
});
const mapDispatchToProps = (dispatch) => bindActionCreators({ initForm, alterarSenha }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UsuarioAlterarSenha);
